<template>
  <div id="user_profile">
    <b-row>
      <b-col sm="12">
        <iq-card class="iq-card">
          <div class="iq-card-body profile-page p-0 profilePages">
            <div class="profile-header">
              <div class="cover-container" style="overflow:hidden;">
                <img v-if="vmCoverPic" :src="vmCoverPic" class="rounded img-fluid" style="object-fit: contain; width: 100%;">
                <img v-else :src="vmBannerImage" class="rounded img-fluid" style="object-fit: contain; width: 100%;">
              </div>

              <div class="user-detail text-center mb-3" v-if="!isMobileDevice">
                <ul class="header-nav d-flex flex-wrap justify-end p-0 m-0">
                  <li>
                    <a href="javascript:void(0);" v-if="checkOrgEditAccess()" @click="goToOrganisationEdit()" title="Edit"><i class="ri-pencil-line"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" v-if="orgType == 'ORG' && (userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118')" @click="goToSimplifiedOrganisationEdit()" title="Edit Organisation"><i class="fa-solid fa-file-pen"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" v-if="cvOrgType == 'SCH' && checkOrgEditAccess()" @click="goToGpathSchoolDashboard()" title="GPaths School Dashboard"><i class="fa fa-map-signs"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" title="Followers" v-if="checkOrgEditAccess() && cvOrgType == 'UNIV'" @click="goToOrganisationFollowList()" ><i class="fa fa-users"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" title="Courses" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111') && cvOrgType == 'UNIV'" @click="goToOrganisationCourses()" ><i class="fa fa-book"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" title="Dashboard" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111') && cvOrgType == 'ORG'" @click="goToOrgDashboard()"><i class="fa fa-tachometer"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" title="Delete" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111')" @click="showOrganisationDeleteDialog(orgObj)" ><i class="ri-delete-bin-line m-0"></i></a>
                  </li>
                  <li>
                    <a  href="javascript:void(0);" title="T&C" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111') && cvOrgType == 'UNIV' && orgObj.org_tnc != 1" @click="organisationEditTnc($event, orgObj)">
                     <i style="color:var(--iq-danger)" class="fa fa-handshake-o"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" title="T&C" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111') && cvOrgType == 'UNIV' && orgObj.org_tnc == 1" @click="organisationEditTnc($event, orgObj)" >
                      <i style="color:var(--green);" class="fa fa-handshake-o"  title="T&C"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" title="T&C" v-if="checkOrgEditAccess() && cvOrgType == 'SCH'" @click="goToMyStudentEvent()" >
                      <i class="fa fa-solid fa-calendar"  title="Events"></i>
                    </a>
                  </li>
                   <li>
                    <a href="javascript:void(0);" title="unApprove" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111') && (orgObj.deleted !== 3 && orgObj.deleted !== 1)" @click="showOrganisationUnapproveDialog(orgObj)" ><i class="fa fa-ban m-0"></i></a>
                  </li>
                </ul>
              </div>

              <div class="user-detail text-center mb-3">
                <div class="profile-img" >
                  <img v-if="vmProfilePic" :src="vmProfilePic" alt="profile-img" class="img-fluid rounded-circle orgLogo" />
                  <b-avatar v-else variant="primary" class="avatar-100 rounded" :text="getFirstLetterOfAString(orgObj.org_name)" style="border-radius: 100%!important;"></b-avatar>
                </div>
                <h3 class="p-1">{{orgObj.org_name}}</h3>
                <div class="profile-detail">
                  <h3 class="details_flag" v-if="allowedToShow() && isMobileDevice">
                    <!-- Show Profile Menu Items -->
                    <div class="menu_icon iq-card-header-toolbar profileMoreBtn">
                      <b-dropdown id="dropdownMenuButton40" variant="none" menu-class="p-0 mr-0">
                        <template v-slot:button-content>
                          <b-link>
                            <i class="ml-0 p-0 mt-1 fa-solid fa-ellipsis-vertical primary-color"></i>
                          </b-link>
                        </template>
                        <a class="dropdown-item p-3" v-if="checkOrgEditAccess()" @click="goToOrganisationEdit()">
                          <div class="icon font-size-20 primary-color">
                            <i style="position: relative;top: 0px;" class="fa fa-edit"></i>&nbsp;Edit
                          </div>
                        </a>
                        <a class="dropdown-item p-3" v-if="checkOrgEditAccess() && cvOrgType == 'UNIV'" @click="goToOrganisationFollowList()">
                          <div class="icon font-size-20 primary-color">
                            <i style="position: relative;top: 0px;" class="fa fa-users"></i>&nbsp;Followers
                          </div>
                        </a>
                        <a class="dropdown-item p-3" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111') && cvOrgType == 'UNIV'" @click="goToOrganisationCourses()">
                          <div class="icon font-size-20 primary-color">
                            <i style="position: relative;top: 0px;" class="fa fa-book"></i>&nbsp;Courses
                          </div>
                        </a>
                        <a class="dropdown-item p-3" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111')" @click="showOrganisationDeleteDialog(orgObj)">
                          <div class="icon font-size-20 primary-color">
                            <i style="position: relative;top: 0px;" class="fa fa-trash-o"></i>&nbsp;Delete
                          </div>
                        </a>
                        <a class="dropdown-item p-3" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111') && cvOrgType == 'UNIV' && orgObj.org_tnc != 1" @click="organisationEditTnc($event, orgObj)">
                          <div class="icon font-size-20 primary-color">
                            <i style="position: relative;top: 0px;" class="primary-color fa fa-handshake-o"></i>&nbsp;Terms
                          </div>
                        </a>
                        <a class="dropdown-item p-3" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111') && cvOrgType == 'UNIV' && orgObj.org_tnc == 1" @click="organisationEditTnc($event, orgObj)">
                          <div class="icon font-size-20 primary-color">
                            <i style="position: relative;top: 0px;" class="success-color fa fa-handshake-o"></i>&nbsp;Terms
                          </div>
                        </a>
                        <a class="dropdown-item p-3" v-if="checkOrgEditAccess()" @click="goToMyStudentEvent()">
                          <div class="icon font-size-20 primary-color">
                            <i style="position: relative;top: 0px;" class="fa fa-solid fa-calendar"></i>&nbsp;Events
                          </div>
                        </a>
                        <a class="dropdown-item p-3" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111') && (orgObj.deleted !== 3 && orgObj.deleted !== 1)" @click="showOrganisationUnapproveDialog(orgObj)">
                          <div class="icon font-size-20 primary-color">
                            <i style="position: relative;top: 0px;" class="fa fa-ban"></i>&nbsp;UnApprove
                          </div>
                        </a>
                        <!-- <a class="dropdown-item p-3" @click="goToMyStudentAdmissions()">
                          <div class="icon font-size-20 primary-color">
                            <i style="position: relative;top: 0px;" class="fa fa-solid fa-file-text"></i>
                          </div>
                        </a> -->
                      </b-dropdown>
                    </div><!-- Show Profile Menu Items -->

                    {{orgObj.org_name}}<br>
                    {{orgObj.org_city ? orgObj.org_city : '' }}
                    {{orgObj.org_state ? ', ' + orgObj.org_state : '' }}
                    {{orgObj.org_country ? ', ' + orgObj.org_country : '' }}
                  </h3>
                </div>
                <div class="row postFollowCountDiv mt-4 m-0">
                  <template v-for="(i,index) in socialInfo">
                    <div :class="i.name == 'Posts' ? 'col-3 col-md-4' : i.name === 'Follow' ? 'col-6 col-md-4' : 'col-3 pl-0 col-md-4'" :key="index">
                      <div v-if="i.name === 'Follow'">
                        <button class="px-3 py-2 btn secondaryEffectBtn rounded mr-2 mb-2" @click="followOrganisation(orgFollowObj.followed_by_curr_user)">
                          <span>{{orgFollowObj.followed_by_curr_user === 0 ? 'Follow' : 'Unfollow' }}</span>
                        </button>
                        <button v-if="showEnroll()" title="Apply for admission" class="px-3 py-2 btn secondaryEffectBtn rounded mr-2 mb-2" @click="enrollAdmission()">
                          <span>Apply</span>
                        </button>
                        <button class="px-2 py-2 btn secondaryEffectBtn rounded today_btn" @click="gotoCurrentEvent()" v-if="checkOrgEditAccess()" >
                          <span>Today's Event</span>
                        </button>
                      </div>
                      <div class="text-center pr-0" v-else>
                        <h6>
                          {{i.name}}
                        </h6>
                        <p class="mb-0">
                          {{i.value}}
                        </p>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </iq-card>
        <iq-card class="iq-card">
          <div class="iq-card-body p-0 showFlexListDiv">
            <div class="user-tabing showFlexList">
              <tab-nav :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0 w-100 ">
                <div  class="flex-fill p-0"  @click="loadTabData(false, 'about')">
                  <tab-nav-items class="flex-fill p-0"  :active="aboutActive" id="pills-activity-tab" href="#profile-activity" ariaControls="pills-profile" role="tab" :ariaSelected="aboutActive" :title="orgTypeFull + ' Profile'" />
                </div>
                <div  class="flex-fill p-0" @click="loadTabData(false, 'feed')">
                  <tab-nav-items class="flex-fill p-0" :active="feedActive" id="pills-feed-tab" href="#profile-feed" ariaControls="pills-home" role="tab" :ariaSelected="feedActive" :title="orgTypeFull + ' Posts'" />
                </div>
                <div  class="flex-fill p-0" @click="loadTabData(false, 'photo')">
                  <tab-nav-items  class="flex-fill p-0" :active="photoActive" id="pills-photo-tab"  href="#photoActive"  ariaControls="pills-photo" role="tab" :ariaSelected="photoActive" title="Photos" />
                </div>
                <div  class="flex-fill p-0" @click="loadTabData(false, 'video')">
                  <tab-nav-items  class="flex-fill p-0" :active="videoActive" id="pills-video-tab"  href="#videoActive"  ariaControls="pills-video" role="tab" :ariaSelected="videoActive" title="Videos" />
                </div>
                <div  class="flex-fill p-0" @click="loadTabData(true, 'follower')">
                  <tab-nav-items  class="flex-fill p-0" :active="followerActive" id="pills-follower-tab"  href="#pills-follower"  ariaControls="pills-follower" role="tab" :ariaSelected="followerActive" title="Followers" />
                </div>
                <div v-if="allowedToShow()" class="flex-fill p-0" @click="loadTabData(false, 'Dashboard')">
                  <tab-nav-items class="flex-fill p-0" :active="dashboardActive" id="pills-dashboard-tab" href="#profile-dashboard" ariaControls="pills-dashboard" role="tab" :ariaSelected="dashboardActive" title="Dashboard" />
                </div>
              </tab-nav>
            </div>
          </div>
        </iq-card>
      </b-col>
      <b-col sm="12">
      <b-col sm="12">
        <div class="tab-content">
          <tab-content-item :active="dashboardActive" id="profile-dashboard" aria-labelled-by="pills-dashboard-tab">
            <div>
              <b-row>
                <b-col lg="12" id="user_list" v-if="cvOrgId">
                  <UserListSchoolDashboard :propOrgId="cvOrgId"></UserListSchoolDashboard>
                </b-col>
              </b-row>
            </div>
          </tab-content-item>
        </div>
      </b-col>
        <div class="tab-content">
          <tab-content-item :active="feedActive" id="profile-feed" aria-labelled-by="pills-feed-tab">
            <div class="iq-card-body p-0">
              <b-row>
                <b-col lg="4">
                  <ClubHomeList v-if="!sideBarItemsList.Clubs.exludeUserRole.includes(userData.user_role) && cvOrgId" :propModuleObjId="cvOrgId"/>
                  <EventHomeList v-if="!sideBarItemsList.Activities.exludeUserRole.includes(userData.user_role) && cvOrgId" :propModuleId="cvOrgId" :propModuleName="propModuleName" :propShowCreateEvent="orgAccess.organisation_edit" />
                  <b-col lg="12" class="p-0">
                    <iq-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">
                          Photos
                        </h4>
                      </template>
                      <template v-slot:headerAction>
                        <p class="m-0 pointer">
                          <a @click="loadTabData(false, 'photo')"> View all
                          </a>
                          <span>
                            <!-- <i class="fa-solid fa-circle-plus"></i> -->
                          </span>
                        </p>
                      </template>
                      <template v-slot:body>
                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                          <li class="col-md-4 col-6 pl-2 pr-0 pb-3 previewImages" v-if="checkOrgEditAccess()">
                            <a>
                              <img :src="addImage" class="img-fluid">
                            </a>
                            <input type="file" style="height: 100%;" class="uploadImagesInput pointer" title="Select photos" id="inputPhoto" ref="inputPhoto"  @change="onImageChange" multiple accept="image/png, image/jpeg" />
                          </li>
                          <li class="col-md-4 col-6 pl-2 pr-0 pb-3 previewImages" v-for="(item,index) in vmImagePreview" :key="index+'v'">
                            <a href="#"><img :src="item.image_medium" alt="gallary-image" class="img-fluid" /></a>
                          </li>
                          <li class="col-md-4 col-6 pl-2 pr-0 pb-3" v-for="(item,index) in organisationImages" :key="index">
                            <a @click="showModelMedia = true; mediaIndex = index"><img :src="item.image" alt="gallary-image" class="img-fluid" /></a>
                          </li>
                        </ul>
                      </template>
                    </iq-card>
                    <iq-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">
                          Videos
                        </h4>
                      </template>
                      <template v-slot:headerAction>
                        <p class="m-0 pointer">
                          <a @click="loadTabData(false, 'video')">View All</a>
                        </p>
                      </template>
                      <template v-slot:body>
                        <ul class="profile-video-gallary d-flex flex-wrap p-0 m-0">
                          <li class="col-md-12 col-sm-12 pl-2 pr-0 pb-3 videoList" v-if="checkOrgEditAccess()">
                            <img :src="addImage" @click="showModelVideo = true" class="img-fluid">
                          </li>
                          <li class="col-md-12 col-sm-12 pl-2 pr-0 pb-3 videoList" v-for="(item,index) in organisationVideos" :key="index">
                            <iframe :src="item.embeded" class="rounded" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </li>
                        </ul>
                      </template>
                    </iq-card>
                  </b-col>
                </b-col>
                <b-col lg="8">
                  <div id="post-modal-data" v-if="orgObj.org_id">
                    <div v-if="containsUserDataModules">
                      <PostAdd :propPostType="1" :propModuleObjId="cvOrgId"/>
                    </div>
                    <div id="post-modal-data">
                      <PostList :propPostType="1" :propModuleId="orgObj.org_id"/>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </tab-content-item>
          <tab-content-item :active="videoActive" id="profile-activity" aria-labelled-by="pills-activity-tab">
            <div class="iq-card">
              <div class="iq-card-body" v-if="orgObj.org_id && videoActive">
                <Photos :propModuleId="orgObj.org_id" :propShowAdd="checkOrgEditAccess()" :propModuleType="orgType" :propType="5" :propMediaType="'video'" />
              </div>
            </div>
          </tab-content-item>
          <tab-content-item :active="photoActive" id="profile-activity" aria-labelled-by="pills-activity-tab">
            <div class="iq-card">
              <div class="iq-card-body" v-if="orgObj.org_id && photoActive">
                <Photos :propModuleId="orgObj.org_id" :propShowAdd="checkOrgEditAccess()" :propModuleType="orgType" :propType="5" />
              </div>
            </div>
          </tab-content-item>
          <tab-content-item :active="aboutActive" id="profile-activity" aria-labelled-by="pills-activity-tab">
            <About :propOrganisationObj="orgObj"/>
          </tab-content-item>
          <tab-content-item :active="followerActive" id="profile-follow" aria-labelled-by="profile-follow-tab">
            <div class="iq-card">
              <div class="iq-card-body">
                <FollowerList :propFireApi="followerApiFire" :propModuleId="cvOrgId" :propFollowedByCurrUser="followStatusOfCurrUser" />
              </div>
            </div>
          </tab-content-item>
        </div>
      </b-col>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>

    <b-modal
      v-model="showModelVideo"
      :title="cvVideoAddTitle">
      <template>
        <div>
          <b-row>
            <b-col lg="12">
              <div class="col-md-12 mb-3">
                <label for="validationcarr_field">
                  Video link
                </label>
                <input
                  v-model="vmVideoLink"
                  type="text"
                  class="form-control"/>
                <span style="font-size: 13px;"><span style="color:red">* </span>{{cvVideoUrlValidMsg}}</span>
              </div>
            </b-col>
            <b-col lg="12">
              <ul class="videoLinkList">
                <li v-for="(item, index) of allVideoLinks" :key="index">
                  {{item.video}}
                  <i class="fa-solid fa-circle-xmark closeIcon" @click="removeVideoLink(index)"></i>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </template>
      <template #modal-footer="">
        <!-- <b-button size="sm" class="pull-left" @click="addVideoLink()">
          Add
        </b-button> -->
        <b-button size="sm" class="pull-left" @click="addVideos()">
          Submit
        </b-button>
        <b-button size="sm" class="pull-left" @click="showModelVideo = false">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelOrganisationUnapprove" size="sm" title="UnApprove " ok-title="Unapprove" cancel-title="Cancel">
      <p>
        {{cvUnApproveMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelOrganisationUnapprove = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="organisationPublishUnpublish()">
          {{cvbtnUnapprove}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelOrganisationDelete" v-if = "showModelOrganisationDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelOrganisationDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="organisationDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelMedia"
      scrollable
      size="lg"
      hide-footer no-close-on-backdrop
      modal-class="mediaDialog">
      <MediaView :propMedia="organisationImages" :propIndex="mediaIndex" />
    </b-modal>

    <!-- <Admission v-if="orgObj.org_id && admissionOpenModal" :propModuleObjId="orgObj.org_id"
              :propOpenedInModal="admissionOpenModal" :propAdmissionObj="admissonObj" @emitCloseAdmissionAddModal="closeAdmissionModal">
    </Admission> -->
  </div>
</template>

<script>
import { socialvue } from "../../../config/pluginInit.js"
import SideBarMenu from "../../../Utils/sidebarMenu.js"
import About from "./About.vue"
import { Organisations } from "../../../FackApi/api/organisation.js"
import { Images } from "../../../FackApi/api/image.js"
import { Follows } from "../../../FackApi/api/follow.js"
import ClubHomeList from "../ListPages/Club/ClubHomeList.vue"
import FollowerList from "../../../components/FollowerList.vue"
import EventHomeList from "../Event/EventHomeList.vue"
import ApiResponse from "../../../Utils/apiResponse.js"
import Utility from "../../../Utils/utility.js"
import Photos from "../Photos.vue"
import MediaView from "../MediaView.vue"
import { Videos } from "../../../FackApi/api/video.js"
import addImage from "/src/assets_gide/img/img/addPic.webp"
import PostList from "../Post/PostList.vue"
import PostAdd from "../Post/PostAdd.vue"
import UserListSchoolDashboard from "../../Gide/User/UserListSchoolDashboard.vue"

export default {
  name: "Profile",
  components: {
    FollowerList,
    About,
    PostList,
    ClubHomeList,
    EventHomeList,
    Photos,
    MediaView,
    PostAdd,
    UserListSchoolDashboard
    // Admission
  },
  data () {
    return {
      socialInfo: [
        {
          name: "Posts",
          value: 0
        },
        {
          name: "Follow"
          // value: 0
        },
        {
          name: "Followers",
          value: 0
        }
      ],
      cvOrgId: "",
      cvorganisationEditHeader: "Organisation Edit",
      showModalorganisationEdit: false,
      photos: [],
      profileImages: [],
      orgObj: {},
      orgType: null,
      orgFollowObj: {},
      vmProfilePic: "",
      vmCoverPic: "",
      vmFollowData: Object.assign({}, this.initFollowData()),
      orgAccess: {
        "organisation_edit": false
      },
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are You Sure?",
      cvUnApproveMessage: "Are you Sure you want to unApprove ?",
      cvbtnModalCancel: "Cancel",
      cvbtnUnapprove: "UnApprove",
      containsUserDataModules: false,
      followStatusOfCurrUser: 0,
      followerApiFire: false,
      feedActive: false,
      dashboardActive: false,
      aboutActive: true, // Show on Page Load
      followerActive: false,
      photoActive: false,
      videoActive: false,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Organisation Response",
      propModuleName: "ORG",
      vmBannerImage: "",
      delObj: {},
      showModelOrganisationDelete: false,
      showModelOrganisationUnapprove: false,
      organisationImages: [],
      organisationVideos: [],
      cvImageReading: false,
      vmImageData: Object.assign({}, this.initImageData()),
      vmImagePreview: [],
      cvVideoAddTitle: "Add Video",
      showModelVideo: false,
      vmVideoLink: null,
      allVideoLinks: [],
      cvVideoUrlMsg: "Only youtube url is supported",
      cvVideoUrlValidMsg: "Currently only youtube links are supported",
      showModelMedia: false,
      mediaIndex: 0,
      addImage: addImage,
      admissionOpenModal: false,
      admissonObj: {},
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList(),
      orgTypeFull: "Organisation",
      isMobileDevice: window.__IS__MOBILE_DEVICE__
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    this.cvOrgType = this.$route.params.org_type
    this.cvOrgId = this.$route.params.org_id

    switch (this.cvOrgType) {
      case "SCH":
        this.orgTypeFull = "School"
        break
      case "UNIV":
        this.orgTypeFull = "University"
        break
      case "ORG":
      default:
        this.orgTypeFull = "Organisation"
        break
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  methods: {
    /**
     * allowedToShow
     */
    allowedToShow () {
      if (this.userData.user_role == "USERROLE11111" ||
        this.userData.user_role == "USERROLE11118" ||
        (this.userData.user_role == "USERROLE11117" &&
          this.userData?.modules?.organisations &&
          this.userData.modules.organisations[this.cvOrgId] &&
          this.userData.modules.organisations[this.cvOrgId].org_id == this.cvOrgId
        )
      ) {
        return true
      }

      return false
    },
    /**
     * showOrganisationDeleteDialog
     */
    showOrganisationDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelOrganisationDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showOrganisationDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * organisationDelete
     */
    async organisationDelete () {
      try {
        let organisationDelResp = await Organisations.organisationDelete(this, this.delObj.org_id)
        await ApiResponse.responseMessageDisplay(this, organisationDelResp)

        if (organisationDelResp && !organisationDelResp) {
          this.showModelOrganisationDelete = false
          return false
        }
        this.delObj.deleted = 1
        this.showModelOrganisationDelete = false
      }
      catch (err) {
        console.error("Exception occurred at organisationDelete() and Exception:", err.message)
      }
    },
    /**
     * showOrganisationUnApproveDialog
      */
    showOrganisationUnapproveDialog (item) {
      try {
        this.unApproveObj = item
        this.showModelOrganisationUnapprove = true
      }
      catch (err) {
        console.error("Exception occurred at showOrganisationDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * Publish/UnPublish Organisation
     */
    async organisationPublishUnpublish () {
      try {
        let payload = {
          org_id: this.unApproveObj.org_id
        }

        if (this.unApproveObj.deleted === 0 || this.unApproveObj.deleted === 2) {
          payload.deleted = 3
        }

        let orgResp = await Organisations.organisation_publish_unpublish(this, payload)
        await ApiResponse.responseMessageDisplay(this, orgResp)
        if (orgResp.resp_status === true) {
          this.unApproveObj.deleted = payload.deleted
          this.showModelOrganisationUnapprove = false
        }
        else {
          ApiResponse.responseMessageDisplay(this, orgResp)
          this.showModelOrganisationUnapprove = false
        }
      }
      catch (err) {
        console.error("Exception occurred at showUnapproveOrgDialog() and Exception:", err.message)
      }
    },
    /**
     * goToOrganisationFollowList
     */
    goToOrganisationFollowList () {
      this.$router.push(`/followerReport/${this.cvOrgId}`)
    },
    /**
     * goToOrganisationCourses
     */
    goToOrganisationCourses () {
      this.$router.push(`/organisationCourse_list/${this.cvOrgId}`)
    },
    /**
     * goToGpathSchoolDashboard
     */
    goToGpathSchoolDashboard () {
      this.$router.push(`/gpath_school_dashboard/${this.cvOrgId}`)
    },
    /**
     * goToOrgDomainValidate
     */
    goToOrgDomainValidate () {
      this.$router.push(`/org_domain_validate/${this.cvOrgType}/${this.cvOrgId}`)
    },
    /**
     * goToOrgDashboard
     */
    goToOrgDashboard () {
      this.$router.push(`/dashboard?org_id=${this.cvOrgId}`)
    },
    /**
     * showEnroll
     */
    showEnroll () {
      if (this.orgObj.org_tnc === 1 && this.userData.user_role === "USERROLE11114") {
        return true
      }
      return false
    },
    /**
     * enrollAdmission
     */
    async enrollAdmission () {
      this.$router.push(`/admission/add/${this.orgObj.org_id}`)
    },
    /**
     * gotoCurrentEvent
     */
    gotoCurrentEvent () {
      let url = `/public/GideViewCurrentEvent/${this.orgObj.org_id}`
      window.open(url, "_blank")
    },
    /**
     * My Student Events
     */
    goToMyStudentEvent () {
      this.$router.push(`/event_analytic_school/${this.cvOrgId}`)
    },
    /**
     * My Student Events
     */
    goToMyStudentAdmissions () {
      this.$router.push("/admissionAnalyticsSchoolWise")
    },
    /**
     * closeAdmissionModal
    */
    closeAdmissionModal (event) {
      this.admissionOpenModal = false
      if (event && event.resp_status) {
        this.admissonObj = event.resp_data
      }
    },
    /**
     * emitOpenAboutTab
     */
    emitOpenAboutTab () {
      this.loadTabData(false, "about")
    },
    /**
     * initImageData
    */
    initImageData () {
      return {
        module_id: "",
        module_name: this.orgType,
        image: [],
        image_type: {}
      }
    },
    /**
     * organisation Edit Tnc Update 0 to 1 and 1 to 0
     */
    async organisationEditTnc (event, orgObj) {
      event.stopPropagation()
      let toastMsg = {
        resp_status: null,
        resp_msg: null
      }

      let payload = {
        org_id: orgObj.org_id,
        org_tnc: null
      }

      if (orgObj.org_tnc === 0) {
        // if org_tnc is 0 then make org_tnc to 1
        payload.org_tnc = 1
        toastMsg.resp_msg = "Terms and conditions accepted"
        toastMsg.resp_status = true
      }
      else if (orgObj.org_tnc === 1) {
        payload.org_tnc = 0
        toastMsg.resp_msg = "Terms and conditions removed"
        toastMsg.resp_status = false
      }
      else {
        return
      }

      let orgResp = await Organisations.organisationEditTnc(this, payload)
      if (orgResp.resp_status === true) {
        orgObj.org_tnc = payload.org_tnc
      }

      ApiResponse.responseMessageDisplay(this, toastMsg)
    },
    /**
     * addVideoLink
     */
    addVideoLink () {
      if (this.vmVideoLink) {
        let youtubeUrlRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm
        let videoIdRegex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
        let obj = {
          video: this.vmVideoLink,
          embeded: ""
        }
        // check url is whether youtube url or not
        let validUrl = youtubeUrlRegex.test(this.vmVideoLink)
        if (!validUrl) {
          this.showToast = true
          this.toastMsg = this.cvVideoUrlMsg
          this.vmVideoLink = ""
          return false
        }
        let videoIdFound = this.vmVideoLink.match(videoIdRegex)
        if (!videoIdFound) {
          this.vmVideoLink = ""
          this.cvVideoError = true
          this.toastMsg = this.cvVideoUrlValidMsg
          return false
        }
        else {
          this.cvVideoError = false
          this.toastMsg = ""
          let videoId = videoIdFound[1]
          obj.embeded = `https://www.youtube.com/embed/${videoId}`
        }
        this.allVideoLinks.push(obj)
        this.vmVideoLink = null
        return true
      }
    },
    /**
     * addVideos
     */
    async addVideos () {
      if (!this.addVideoLink()) {
        return
      }
      const videoObj = {
        module_id: this.orgObj.org_id,
        module_name: this.orgType,
        videosLinks: JSON.stringify(this.allVideoLinks)
      }
      const videoAddResp = await Videos.videoAdd(this, videoObj)
      if (videoAddResp && videoAddResp.resp_status) {
        this.showModelVideo = false
        this.vmVideoLink = null
        this.organisationVideos.unshift(...this.allVideoLinks)
        this.allVideoLinks = []
      }
    },
    /**
     * removeVideoLink
     */
    removeVideoLink (index) {
      this.allVideoLinks.splice(index, 1)
    },
    /**
     * onImageChange
     */
    async onImageChange (e) {
      try {
        let input = e.target
        if (input.files.length > 0) {
          this.cvImageReading = true

          let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]

          let imageValidExtension = input.files.length > 0 ? Object.values(input.files).map(file => allowedExtension.includes(file.type)) : null

          if (imageValidExtension && imageValidExtension.includes(false)) {
            this.showToast = true
            this.errorMsg = "Only jpeg, webp and png is supported."
          }
          else {
            this.cvImageError = false
            this.cvImageErrorAlert = false
            this.errorMsg = ""
          }

          let images = Object.values(input.files).slice(0, 5)

          this.vmImageData.image = images

          for (const index in images) {
            let image = images[index]
            let imageData = {}
            const reader = new FileReader()
            this.vmImageData.image_type[image.name] = 5
            reader.onload = async (e) => {
              let dataUrl = await e.target.result
              imageData = {
                image: dataUrl,
                image_medium: dataUrl,
                image_thumbnail: ""
              }
              this.$set(this.vmImagePreview, index, imageData)
            }
            reader.readAsDataURL(image)
            imageData.image_medium = ""
          }
          this.imageAdd(this.orgObj.org_id)
          this.cvImageReading = false
        }
      }
      catch (err) {
        this.cvImageReading = false
        console.error("Exception occurred at onImageChange() and Exception:", err.message)
      }
    },
    /**
    * imageAdd
    */
    async imageAdd (moduleId) {
      try {
        this.vmImageData.module_id = moduleId
        const imageAddResp = await Images.imageAdd(this, this.vmImageData)
        await ApiResponse.responseMessageDisplay(this, imageAddResp)

        if (imageAddResp && !imageAddResp.resp_status) {
          return false
        }
        else {
          this.organisationImages.unshift(...this.vmImagePreview)
          this.organisationImages = [ ...this.organisationImages.splice(0, 7) ]
          this.vmImagePreview = []
        }

        this.vmImageData = Object.assign({}, this.initImageData())
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
      }
    },
    /**
    *checkOrgEditAccess
    */
    checkOrgEditAccess () {
      if (this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118" || (this.orgAccess && this.orgAccess.organisation_edit)) {
        return true
      }
      else {
        return false
      }
    },
    /**
     * initFollowData
     */
    initFollowData () {
      return {
        module_id: "",
        module_name: "",
        follow_status: 0
      }
    },
    addPost (post) {
      this.socialPosts.unshift(post)
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      this.orgType = this.$route.params.org_type
      this.cvOrgId = this.$route.params.org_id
      this.containsUserDataModules = this.userData.modules?.organisations && this.userData.modules?.organisations[this.cvOrgId]
      this.organisationView()
      socialvue.index()
      this.setUserRoles()
      this.imageGetProfileImages()
    },
    /**
     * getUserRoles
     */
    setUserRoles () {
      let orgId = this.$route.params.org_id
      if (this.userData && this.userData.roles && this.userData.roles[orgId] && this.userData.roles[orgId]["actions"]) {
        if (this.userData.roles[orgId].udr_name && (this.userData.roles[orgId].udr_name.toLowerCase() === "admin" || this.userData.roles[orgId].udr_name.toLowerCase() === "owner")) {
          this.orgAccess.organisation_edit = true
          return
        }

        let actions = this.userData.roles[orgId]["actions"]
        let orgEditAccess = actions.findIndex(obj => {
          return obj.api_name.toLowerCase() === "organisation_edit"
        })

        if (orgEditAccess > -1) {
          this.orgAccess.organisation_edit = true
        }
      }
    },
    /**
     * organisationList
     */
    async organisationView () {
      try {
        let orgViewResp = await Organisations.organisationView(this, this.cvOrgId)
        if (orgViewResp && orgViewResp.resp_status) {
          this.orgObj = orgViewResp.resp_data.data
          this.organisationImages = orgViewResp.photos
          this.organisationVideos = orgViewResp.videos
          this.admissonObj = orgViewResp.admisson

          this.vmBannerImage = Utility.getBgImageForLetter(this, this.orgObj.org_name)

          this.followStatusOfCurrUser = orgViewResp.organisation_follow.followed_by_curr_user
          this.orgFollowObj = orgViewResp.organisation_follow

          if (orgViewResp.post_count) {
            this.socialInfo[0]["value"] = orgViewResp.post_count
          }

          if (orgViewResp.organisation_follow && orgViewResp.organisation_follow.follow_count) {
            this.socialInfo[2].value = orgViewResp.organisation_follow.follow_count
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationView() and Exception:", err.message)
      }
    },
    /**
     * imageGetProfileImages()
     */
    async imageGetProfileImages () {
      try {
        let imageProfileResp = await Images.imageListProfileImages(this, this.cvOrgId)
        if (imageProfileResp && !imageProfileResp.resp_status) {
          return false
        }
        else {
          this.vmProfilePic = imageProfileResp.resp_data.profile_pic ? imageProfileResp.resp_data.profile_pic.image_thumbnail : ""
          this.vmCoverPic = imageProfileResp.resp_data.cover_pic ? imageProfileResp.resp_data.cover_pic.image : ""
        }
      }
      catch (err) {
        console.error("Exception occurred at imageGetProfileImages() and Exception:", err.message)
      }
    },
    /**
     * goToOrganisationEdit
     */
    goToOrganisationEdit () {
      try {
        if (this.orgObj && Object.keys(this.orgObj).length > 0) {
          if (this.userData.user_role == "USERROLE11116") {
            // External counsellor
            this.$router.push("/org_edit/" + this.orgObj.type + "/" + this.orgObj.org_id)
          }
          else {
            this.$router.push("/organisation_edit/" + this.orgObj.type + "/" + this.orgObj.org_id)
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at organisationEdit() and Exception:", err.message)
      }
    },
    /*
     * goToSimplifiedOrganisationEdit
    */
    goToSimplifiedOrganisationEdit () {
      this.$router.push(`/org_edit/ORG/${this.cvOrgId}`)
    },
    /**
     * closeOrganisationEditModal
     */
    closeOrganisationEditModal () {
      try {
        this.showModalorganisationEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeOrganisationEditModal() and Exception:", err.message)
      }
    },
    /**
     *  organisationFollow ()
     */
    async followOrganisation (followStatus) {
      try {
        this.vmFollowData.module_id = this.cvOrgId
        this.vmFollowData.module_name = this.orgType
        this.vmFollowData.follow_status = followStatus ? 0 : 1
        let followResp = await Follows.followAdd(this, this.vmFollowData)
        await ApiResponse.responseMessageDisplay(this, followResp)
        if (followResp && !followResp.resp_status) {
          return
        }
        else {
          this.orgFollowObj.followed_by_curr_user = this.vmFollowData.follow_status
          this.followStatusOfCurrUser = this.vmFollowData.follow_status
          if (followStatus) {
            this.orgFollowObj.follow_count--
          }
          else {
            this.orgFollowObj.follow_count++
          }
          this.socialInfo[2].value = this.orgFollowObj.follow_count
          this.vmFollowData = Object.assign({}, this.initFollowData())
        }
      }
      catch (err) {
        console.error("Exception occured at followOrganisation() and Exception", err.message)
      }
    },
    /**
     * loadFolloweData
     */
    loadTabData (bool, tabType) {
      try {
        this.followerApiFire = bool
        this.feedActive = false
        this.aboutActive = false
        this.followerActive = false
        this.photoActive = false
        this.videoActive = false
        this.dashboardActive = false

        if (tabType === "feed") {
          this.feedActive = true
        }
        else if (tabType === "Dashboard") {
          this.dashboardActive = true
        }
        else if (tabType === "about") {
          this.aboutActive = true
        }
        else if (tabType === "photo") {
          this.photoActive = true
        }
        else if (tabType === "video") {
          this.videoActive = true
        }
        else {
          this.followerActive = true
        }
      }
      catch (err) {
        console.error("Exception occured at loadTabData() and Exception", err.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width:700px) {
  ul.header-nav {
    bottom: 90px !important;
  }
}
.pointer{
  cursor: pointer;
}
.details_flag{
  margin: auto !important;
  position: relative;
  padding: 0px 40px;
  width: auto;;
  white-space: normal;
}
.profileMoreBtn{
  display: flex;
  float: right;
}
#user_profile .profile-header .user-detail {
    position: relative;
    bottom: 30px;
    margin-top: -40px;
    height: auto;
}
@media (max-width: 767px){
  .user-detail{
    .profile-img{
      .header-nav{
        display: none !important;
      }
    }
  }
}
.menu_icon{
  #dropdownMenuButton40__BV_toggle_{
    a{
      display: block;
      width: 20px;
    }
  }
}
.orgLogo {
  object-fit: contain;
  width: 100px;
  height: 100px !important;
  background: white;
}
.uploadImagesInput {
  position: absolute;
  width: 100%;
  margin-left: -90px;
  opacity: 0;
}
ul.profile-img-gallary {
  li {
    height: 100px;
    width: 100%;
    padding: 6px 3px !important;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
.profile-video-gallary {
  li {
    width: 100%;
    padding: 6px 3px !important;
  }
}
.previewImages {
  img {
    background-attachment: fixed;
    opacity: 0.5;
    &:before{
      content: "Joe's Task: ";
    }
  }
}
.videoLinkList {
  li {
    overflow: hidden;
    word-break: break-all;
    .closeIcon {
      position: relative;
    }
  }
}
.user-images {
  height: 234px;
}
.videoList {
  // height: 150px;
  img, iframe {
    width: 100%;
    height: 230px;
    object-fit: cover;
  }
}
.today_btn{
  position: relative;
  top: -4px;
}
#user_list{
  padding: 0px;
}
</style>
